@import url(https://fonts.googleapis.com/css2?family=Inconsolata&display=swap);
@font-face {
  font-family: 'Westline Script Demo';
  font-style: normal;
  font-weight: bold;
  src: url(/static/media/westlineScript.b6710dcb.otf);
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font: 400 14px "Inconsolata", sans-serif;
  background: #191b1a;
  -webkit-font-smoothing: antialiased;
}

span,
p,
h1,
a {
  font: "Inconsolata", sans-serif;
}

button {
  cursor: pointer;
}
